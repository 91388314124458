<template>
    <div style="padding-bottom: 68px;">
        <div class="fix-head flex-between">
            <div class="flex-center">
                <img src="../../assets/order_logo.png" alt="logo" width="28px">
                <h1 class="title">订单编号：{{detail.ordersn}}</h1>
            </div>
            <div class="overview flex">
                <div class="overview-item flex">
                    <span class="overview-label">状态</span>
                    <span class="overview-content">{{detail.statusname}}</span>
                </div>
                <div class="overview-item flex">
                    <span class="overview-label">订单金额</span>
                    <span class="overview-content">￥{{detail.real_pay_price / 100}}</span>
                </div>
            </div>
        </div>
        
        <div class="item">
            <div class="head">收货信息</div>
            <div class="main flex-between">
                <div class="main-item">
                    <span class="label">收货人：</span>
                    <span class="contain">{{detail.contactor}}</span>
                </div>
                <div class="main-item">
                    <span class="label">联系方式：</span>
                    <span class="contain">{{detail.mobile}}</span>
                </div>
                <div class="main-item flex">
                    <span class="label">联系地址：</span>
                    <span class="contain">{{detail.address}}</span>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="head">订单信息</div>
            <div class="main flex-between">
                <div class="main-item">
                    <span class="label">订单编号：</span>
                    <span class="contain">{{detail.ordersn}}</span>
                </div>
                <div class="main-item">
                    <span class="label">下单时间：</span>
                    <span class="contain">{{detail.created_at}}</span>
                </div>
                <div class="main-item"/>
                <div class="main-item">
                    <span class="label">订单原价：</span>
                    <span class="contain">{{detail.original_price / 100}}元</span>
                </div>
                <div class="main-item">
                    <span class="label">实付价格：</span>
                    <span class="contain">{{detail.real_pay_price / 100}}元</span>
                </div>
                <div class="main-item">
                    <span class="label">运费：</span>
                    <span class="contain">{{detail.transport_fee / 100}}元</span>
                </div>
                <div class="main-item">
                    <span class="label">订单状态：</span>
                    <el-select v-model="detail.status" placeholder="请选择" style="width: 224px;">
                        <template v-if="status !== 0 && status !== 1">
                            <el-option
                                    v-for="item in statusOptions"
                                    :key="item.status"
                                    :label="item.name"
                                    :value="item.status"
                                    disabled
                            />
                        </template>
                        <el-option v-if="status === 0" label="待支付" :value="0"/>
                        <el-option v-if="status === 0" label="关闭订单" :value="6"/>
                        <el-option v-if="status === 1" label="待发货" :value="1"/>
                        <el-option v-if="status === 1" label="已发货" :value="2"/>
                    </el-select>
                </div>
                <div class="main-item">
                    <span class="label">物流编号：</span>
                    <el-input v-model="detail.delivery_sn" style="width: 224px;" v-if="status === 1"></el-input>
                    <span class="contain" v-else>{{detail.delivery_sn}}</span>
                </div>
                <div class="main-item">
                    <span class="label">物流公司：</span>
                    <span class="contain">{{detail.delivery_company}}</span>
                </div>
                <div class="main-item">
                    <span class="label">订单备注：</span>
                    <span class="contain">{{detail.remark}}</span>
                </div>
                <div class="main-item">
                    <span class="label"></span>
                    <span class="contain"></span>
                </div>
                <div class="main-item">
                    <span class="label"></span>
                    <span class="contain"></span>
                </div>
                <div>
                    <span class="label" style="line-height: 96px;">商家备注：</span>
                    <el-input placeholder="请输入" v-model="detail.business_remark" style="width: 400px;" :rows="4" type="textarea"></el-input>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="head">商品信息</div>
            <div class="main">
                <div class="product" v-for="(item, index) in detail.item_list" :key="index">
                    <div class="product-name">商品{{index + 1}}</div>
                    <div class="flex-between" style="flex-wrap: wrap;">
                        <div class="main-item">
                            <span class="label">商品ID：</span>
                            <span class="contain">{{item.id}}</span>
                        </div>
                        <div class="main-item">
                            <span class="label">商品名称：</span>
                            <span class="contain">{{item.product_name}}</span>
                        </div>
                        <div class="main-item"/>
                        <div class="main-item">
                            <span class="label">规格属性：</span>
                            <el-select v-model="item.sku_id" placeholder="请选择" style="width: 224px;"
                                       v-if="status === 0">
                                <el-option
                                        v-for="op in item.sku_list"
                                        :key="op.sku_id"
                                        :label="op.spec_name_one + op.attribute_name_one + op.spec_name_two + op.attribute_name_two"
                                        :value="op.sku_id"
                                >
                                </el-option>
                            </el-select>
                            <span class="contain" v-else>{{item.sku_name}}</span>
                        </div>
                        <div class="main-item">
                            <span class="label">商品数量：</span>
                            <el-input v-model="item.num" style="width: 224px;" v-if="status === 0"></el-input>
                            <span class="contain" v-else>{{item.num}}</span>
                        </div>
                        <div class="main-item"/>
                        <div class="main-item">
                            <span class="label">市场价格：</span>
                            <span class="contain">{{item.price / 100}}元</span>
                        </div>
                        <div class="main-item">
                            <span class="label">优惠后价格：</span>
                            <span class="contain">{{item.sale_price / 100}}元</span>
                        </div>
                        <div class="main-item">
                            <span class="label">实付价格：</span>
                            <el-input v-model="item.realPay" style="width: 224px;" v-if="status === 0"></el-input>
                            <span class="contain" v-else>{{item.realPay}}元</span>
                        </div>
                    </div>
                </div>
                <el-divider class="divider"></el-divider>
            </div>
        </div>
        <div class="item">
            <div class="head">优惠信息</div>
            <div class="main">
                <div v-if="detail.activity_list && detail.activity_list.length !== 0">
                    <el-table
                            :data="detail.activity_list"
                            class="tb-table">
                        <el-table-column prop="activityName" label="优惠类型"/>
                        <el-table-column label="优惠金额">
                            <template slot-scope="scope">
                                {{scope.row.amount/100.0}}元
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="no-info" v-else>
                    <i class="el-icon-ice-tea"></i>
                    <span>暂无数据</span>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="head">物流信息</div>
            <div class="main">
                <div v-if="detail.delivery_list && detail.delivery_list.length !== 0">
                    <el-table
                            :data="detail.delivery_list"
                            class="tb-table"
                    >
                        <el-table-column prop="content" label="物流更新信息"/>
                        <el-table-column prop="ctime" label="物流更新时间"/>
                    </el-table>
                </div>
                <div class="no-info" v-else>
                    <i class="el-icon-ice-tea"></i>
                    <span>暂无数据</span>
                </div>
            </div>
        </div>
        <div class="fix-confirm">
            <el-button class="tb-button" @click="onCancel">取消</el-button>
            <el-button v-if="status === 2" class="tb-button" @click="onSignfor">签收</el-button>
            <el-button class="tb-button" type="primary" @click="submit">修改</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OrderDetail',
        data() {
            return {
                id: null,
                detail: {},
                input: '',
                value: '',
                options: [],
                statusOptions: [
                    {status: 0, name: '待付款'},
                    {status: 1, name: '待发货'},
                    {status: 2, name: '已发货'},
                    {status: 3, name: '已完成'},
                    {status: 4, name: '部分退款'},
                    {status: 5, name: '全部退款'},
                    {status: 6, name: '关闭订单'}
                ],
                status: ''
            }
        },
        mounted() {
            this.id = this.$route.query.id * 1 || null
            if (this.id) {
                this.getDetail()
            }
        },
        methods: {
            async getDetail() {
                const url = `admin/order/detail?id=${this.id}`
                const data = await this.$https.get(url)
                if (!data) return
                const statusname = ['待付款', '已付款', '已发货', '已完成', '部分退款', '全部退款', '关闭']
                data.item_list.forEach(item => {
                    item.realPay = this.$np.divide(item.pay_price, 100)
                })
                data.statusname = statusname[data.status]
                data.created_at = this.$dayjs(data.created_at).format('YYYY-MM-DD hh:mm')
                this.status = data.status * 1
                this.detail = data
            },
            submit() {
                if (this.detail.status === 2 && !this.detail.delivery_sn) {
                    this.$message({
                        message: '请输入物流单号',
                        type: 'error'
                    })
                    return
                }
                this.$confirm('是否修改此订单?', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const params = {
                        id: this.detail.id,
                        status: this.detail.status,
                        delivery_sn: this.detail.delivery_sn || '',
                        sku_list: [],
                        business_remark: this.detail.business_remark
                    }
                    this.detail.item_list.forEach(item => {
                        const d = {
                            sku_id: item.sku_id,
                            id: item.id,
                            num: item.num,
                            pay_price: this.$np.times(item.realPay, 100)
                        }
                        params.sku_list.push(d)
                    })
                    const url = 'admin/order/edit'
                    this.$https.post(url, params).then(data => {
                        if (data) {
                            this.$message.success('修改成功！')
                            this.getDetail()
                        }
                    })
                }).catch(() => {
                })
            },
            onSignfor() {
                this.$confirm('是否手动签收此订单?', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const params = {
                        id: this.detail.id,
                        status: 3
                    }
                    const url = 'admin/order/edit'
                    this.$https.post(url, params).then(() => {
                        // if (data) 
                        {
                            this.$message.success('修改成功！')
                            this.getDetail()
                        }
                    })
                }).catch(() => {
                })
            },
            onCancel() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .fix-head {
        padding-bottom: 24px;

        .title {
            padding-left: 16px;
        }

        .overview {
            text-align: right;

            &-item {
                flex-direction: column;

                .overview-label {
                    color: rgba(0, 0, 0, 0.45);
                    padding-bottom: 4px;
                }

                .overview-content {
                    font-size: 20px;
                    color: rgba(0, 0, 0, 0.65);
                }
            }

            .overview-item + .overview-item {
                margin-left: 40px;
            }
        }
    }

    .item {
        margin: 24px 24px 0;
        background-color: #fff;

        .head {
            padding: 16px 32px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid #E9E9E9;
            box-sizing: border-box;
        }

        .main {
            padding: 24px 32px;
            flex-wrap: wrap;

            &-item {
                width: 33%;
                margin-top: 16px;

                .label {
                    min-width: 70px;
                    margin-top: 0px;
                    color: rgba(0, 0, 0, 0.85);
                }

                .contain {
                    min-width: 300px;
                    color: rgba(0, 0, 0, 0.65);
                }
            }

            .main-item:nth-child(-n+3) {
                margin-top: 0;
            }

            .product {
                &-name {
                    padding-bottom: 16px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.85);
                }
            }

            .divider {
                margin: 32px 0;
            }

            .no-info {
                text-align: center;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.25);
            }
        }
    }
</style>
